import { post } from 'src/api/api';

import {
  ANALYTICS_ENDPOINT,
  ANALYTICS_ENDPOINT_V2,
} from '@features/Analytics/constants';

export const sendAnalyticsId = async ({
  googlePseudoId,
  userPseudoIdApplePay,
  userPseudoIdWinback,
  mg,
}: {
  googlePseudoId?: string;
  userPseudoIdApplePay?: string;
  userPseudoIdWinback?: string;
  mg: number;
}) =>
  await post({
    endpoint: ANALYTICS_ENDPOINT_V2,
    path: 'id_aggregator',
    body: {
      user_pseudo_id: googlePseudoId,
      origin: 'mimika',
      mg: mg,
      user_pseudo_id_apple_pay: userPseudoIdApplePay,
      user_pseudo_id_winback: userPseudoIdWinback,
    },
  });

export const getAbTest = async ({
  name,
  userId,
  abortSignal,
}: {
  name: string;
  userId?: number | null;
  abortSignal?: AbortSignal;
}) =>
  await post({
    endpoint: ANALYTICS_ENDPOINT,
    path: 'single_web_ab_test',
    body: {
      test_name: name,
      server_id: userId,
      env_type:
        process.env.NEXT_PUBLIC_ENV === 'development' ? 'test' : 'production',
    },
    abortSignal,
  });
