import { SubscriptionPlanSnapshot } from '../models/subscription-plan/subscription-plan';
import { get, post } from './api';

export const ENDPOINT_MICROSERVICE = process.env
  .NEXT_PUBLIC_API_MICROSERVICE_ENDPOINT as string;

const responseToSubscriptionPlan = (
  response: any,
): SubscriptionPlanSnapshot => ({
  id: response['id'],
  name: response['name'],
  currency: response['currency'] != null ? response['currency'] : 'USD',
  priceAfterIntroOffer:
    response['price_after_intro_offer'] != null
      ? response['price_after_intro_offer']
      : response['recurring_price'],
  billingPeriod: response['billing_period'],
  billingPeriodUnit: response['billing_period_unit'],
  trialPeriodDays:
    response['trial_period_days'] != null
      ? response['trial_period_days']
      : response['trial_days'],
  specialOffer: response['special_offer'],
  discountPercentage: response['discount_percentage'],
  displayPrice: response['display_price'],
  fractionalPrice: +response['fractional_price'],
  fractionalPriceDiscount: +response['fractional_price_discount'],
  fullPrice: +response['full_price'],
  fullPriceDiscount: +response['full_price_discount'],
  initialPrice:
    response['initial_price'] != null ? +response['initial_price'] : undefined,
  group: response['group'],
  description: response['description'],
  order: response['order'],
  paddlePlanId:
    response['paddle_plan_id'] != null
      ? +response['paddle_plan_id']
      : undefined,
  paddleIternalName:
    response['paddle_internal_name'] != null
      ? response['paddle_internal_name']
      : undefined,
  paypalPlanId: response['paypal_plan_id'] ?? undefined,
  stripePriceId: response['stripe_price_id'] ?? undefined,
  nuveiPlanId:
    response['nuvei_plan_id'] != null ? +response['nuvei_plan_id'] : undefined,
  braintreePlanId:
    response['braintree_plan_id'] != null
      ? response['braintree_plan_id']
      : undefined,
  hideDescription:
    response['hide_description'] != null
      ? response['hide_description']
      : undefined,
  gift: response['gift'] != null ? response['gift'] : undefined,
  preselected:
    response['preselected'] != null ? response['preselected'] : undefined,
});

export const fetchSubscriptionPlans = async ({
  abortSignal,
  group,
  paddle,
  countryCode,
}: {
  abortSignal?: AbortSignal;
  group?: string;
  paddle?: boolean;
  countryCode?: string;
}): Promise<SubscriptionPlanSnapshot[]> => {
  const responseObject = await get({
    endpoint: ENDPOINT_MICROSERVICE,
    path: paddle ? 'paddle_plans' : 'subscription_plans',
    abortSignal,
    params: { group: group ?? '', country_code: countryCode ?? '' },
  });
  return responseObject.map((planResponseObject: any) =>
    responseToSubscriptionPlan(planResponseObject),
  );
};

export const generateBraintreeClientToken = async ({
  customerId,
  customerEmail,
  abortSignal,
}: {
  customerId: string;
  customerEmail: string;
  abortSignal?: AbortSignal;
}): Promise<string> =>
  await post({
    endpoint: ENDPOINT_MICROSERVICE,
    path: 'braintree_generate_client_token',
    body: {
      user_id: customerId,
      email: customerEmail,
    },
    abortSignal,
  });

export const createBraintreeSubscription = async ({
  customerId,
  customerToken,
  planId,
  paymentMethodNonce,
  paymentMethodVaulted = true,
  abortSignal,
}: {
  customerId: string;
  customerToken: string;
  planId: string;
  paymentMethodNonce: string;
  paymentMethodVaulted?: boolean;
  abortSignal?: AbortSignal;
}): Promise<void> =>
  await post({
    endpoint: ENDPOINT_MICROSERVICE,
    path: 'braintree_create_subscription',
    body: {
      user_id: customerId,
      token: customerToken,
      plan_id: planId,
      payment_method_nonce: paymentMethodNonce,
      payment_method_vaulted: paymentMethodVaulted,
    },
    abortSignal,
  });
