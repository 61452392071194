import classNames from 'classnames';
import {
  ComponentProps,
  ComponentType,
  SVGAttributes,
  forwardRef,
} from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

import LoadingIndicator from '../loading-indicator/loading-indicator';
import styles from './button.module.scss';

type Props = ComponentProps<typeof BootstrapButton> & {
  showLoadingIndicator?: boolean;
  IconComponent?: ComponentType<SVGAttributes<SVGElement>>;
  className?:
    | 'accent-2'
    | 'primary'
    | 'secondary'
    | 'flat'
    | 'outline'
    | 'link';
};

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ className, IconComponent, ...props }: Props, ref) => {
    const {
      iconClassName,
      disabled,
      showLoadingIndicator = false,
      children,
      variant,
      size,
    } = props;

    const variantClassName = classNames(styles.container, {
      [styles.accent2]: variant === 'accent-2',
      [styles.containerPrimary]: variant === 'primary',
      [styles.containerSecondary]: variant === 'secondary',
      [styles.flat]: variant === 'flat',
      [styles.outline]: variant === 'outline',
      [styles.link]: variant === 'link',
    });

    const sizeClassName = classNames({
      [styles.lg]: size === 'lg',
    });

    return (
      <BootstrapButton
        ref={ref}
        className={classNames(
          styles.container,
          variantClassName,
          sizeClassName,
          className,
        )}
        disabled={disabled || showLoadingIndicator}
        {...props}>
        {showLoadingIndicator ? (
          <LoadingIndicator />
        ) : (
          <>
            {IconComponent && (
              <IconComponent
                className={classNames(styles.icon, iconClassName)}
              />
            )}
            {children}
          </>
        )}
      </BootstrapButton>
    );
  },
);

Button.displayName = 'Button';
export default Button;
