type EventName = 'PageVisit' | 'Signup' | 'AddToCart' | 'Checkout';

export const pinterestEvent = (
  name: EventName,
  options?: { [key: string]: any },
) => {
  const randomInt = Math.floor(Math.random() * 100_000_000);
  const eventId = `${name}.${randomInt}`;
  // @ts-ignore

  window?.pintrk?.('track', name, { event_id: eventId, ...options });
};
