import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const initialAbTest: AbTestSnapshot = {
  group_name: null,
  info: {},
};

export const AbTestModel = types.model('AbTest').props({
  group_name: types.maybeNull(types.string),
  info: types.optional(types.model('AbTestInfo'), {}),
});

type AbTestType = Instance<typeof AbTestModel>;
export interface AbTest extends AbTestType {}
type AbTestSnapshotType = SnapshotOut<typeof AbTestModel>;
export interface AbTestSnapshot extends AbTestSnapshotType {}
