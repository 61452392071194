import { Instance, applySnapshot, types } from 'mobx-state-tree';

import { TpSkinType } from 'src/components/main/quiz/recomendations-step/recommendations-step';
import { QuizStep } from 'src/constants/quiz';

export const QuizStoreModel = types
  .model('QuizStore')
  .props({
    country: types.maybe(types.string),
    countryCode: types.maybe(types.string),
    knowledge: types.maybe(types.number),
    age: types.maybe(types.number),
    goal: types.maybe(types.frozen(types.array(types.string))),
    area: types.maybe(types.frozen(types.array(types.string))),
    lookYounger: types.maybe(types.number),
    skinCareRoutine: types.maybe(types.number),
    skinCareProducts: types.maybe(types.frozen(types.array(types.string))),
    sunSafety: types.maybe(types.number),
    facialTreatments: types.maybe(types.number),
    badHabits: types.maybe(types.frozen(types.array(types.string))),
    eatHabits: types.maybe(types.number),
    sugarHabits: types.maybe(types.number),
    vegetablesHabits: types.maybe(types.number),
    waterHabits: types.maybe(types.number),
    sleepHabits: types.maybe(types.number),
    oilyShine: types.maybe(types.number),
    afterCleanFace: types.maybe(types.number),
    skinAcne: types.maybe(types.number),
    skinTexture: types.maybe(types.number),
    stress: types.maybe(types.number),
    skinColor: types.maybe(types.number),
    skinProblems: types.maybe(types.frozen(types.array(types.string))),
    faceShape: types.maybe(types.number),
    jawline: types.maybe(types.number),
    unhappy: types.maybe(types.number),
    darkCircles: types.maybe(types.number),
    lifestyle: types.maybe(types.number),
    work: types.maybe(types.number),
    afraidOfLosingBeauty: types.maybe(types.number),
    appearanceReason: types.maybe(types.number),
    hearAboutUs: types.maybe(types.frozen(types.array(types.string))),
    ebookSwitcher: false,
  })
  .views((self) => ({
    getSingleOptionSelectStepValue: (step: QuizStep) => {
      switch (step) {
        case QuizStep.WELCOME_KNOWLEDGE:
          return self.knowledge;
        case QuizStep.AGE:
          return self.age;
        case QuizStep.DARK_CIRCLES:
          return self.darkCircles;
        case QuizStep.LOOK_YOUNGER:
          return self.lookYounger;
        case QuizStep.SKIN_COLOR:
          return self.skinColor;
        case QuizStep.STRESS:
          return self.stress;
        case QuizStep.JAWLINE:
          return self.jawline;
        case QuizStep.UNHAPPY:
          return self.unhappy;
        case QuizStep.SKIN_CARE_ROUTINE:
          return self.skinCareRoutine;
        case QuizStep.SUN_SAFETY:
          return self.sunSafety;
        case QuizStep.FACIAL_TREATMENTS:
          return self.facialTreatments;
        case QuizStep.EAT_HABITS:
          return self.eatHabits;
        case QuizStep.SUGAR_HABITS:
          return self.sugarHabits;
        case QuizStep.VEGETABLES_HABITS:
          return self.vegetablesHabits;
        case QuizStep.WATER_HABITS:
          return self.waterHabits;
        case QuizStep.SLEEP_HABITS:
          return self.sleepHabits;
        case QuizStep.OILY_SHINE:
          return self.oilyShine;
        case QuizStep.AFTER_CLEAN_FACE:
          return self.afterCleanFace;
        case QuizStep.SKIN_ACNE:
          return self.skinAcne;
        case QuizStep.SKIN_TEXTURE:
          return self.skinTexture;
        case QuizStep.FACE_SHAPE:
          return self.faceShape;
        case QuizStep.LIFESTYLE:
          return self.lifestyle;
        case QuizStep.WORK:
          return self.work;
        case QuizStep.AFRAID_LOSE_BEAUTY:
          return self.afraidOfLosingBeauty;
        case QuizStep.APPEARANCE_REASON:
          return self.appearanceReason;
        default:
          throw new Error('The step is not of type SINGLE_OPTION_SELECT');
      }
    },
    get knowledgeStepCompleted() {
      return self.knowledge != null;
    },
    get ageStepCompleted() {
      return self.age != null;
    },
    get goalStepCompleted() {
      return self.goal != null;
    },
    get areaStepCompleted() {
      return self.area != null && self.area.length > 0;
    },
    get lookYoungerStepCompleted() {
      return self.lookYounger != null;
    },
    get skinCareRoutineStepCompleted() {
      return self.skinCareRoutine != null;
    },
    get skinCareProductsStepCompleted() {
      return self.skinCareProducts != null && self.skinCareProducts.length > 0;
    },
    get sunSafetyStepCompleted() {
      return self.sunSafety != null;
    },
    get facialTreatmentsStepCompleted() {
      return self.facialTreatments != null;
    },
    get badHabitsStepCompleted() {
      return self.badHabits != null && self.badHabits.length > 0;
    },
    get eatHabitsStepCompleted() {
      return self.eatHabits != null;
    },
    get sugarHabitsStepCompleted() {
      return self.sugarHabits != null;
    },
    get vegetablesHabitsStepCompleted() {
      return self.vegetablesHabits != null;
    },
    get waterHabitsStepCompleted() {
      return self.waterHabits != null;
    },
    get sleepHabitsStepCompleted() {
      return self.sleepHabits != null;
    },
    get oilyShineStepCompleted() {
      return self.oilyShine != null;
    },
    get afterCleanFaceStepCompleted() {
      return self.afterCleanFace != null;
    },
    get skinAcneStepCompleted() {
      return self.skinAcne != null;
    },
    get skinTextureStepCompleted() {
      return self.skinTexture != null;
    },
    get stressStepCompleted() {
      return self.stress != null;
    },
    get skinColorStepCompleted() {
      return self.skinColor != null;
    },
    get skinProblemsStepCompleted() {
      return self.skinProblems != null;
    },
    get faceShapeStepCompleted() {
      return self.faceShape != null;
    },
    get jawlineStepCompleted() {
      return self.jawline != null;
    },
    get unhappyStepCompleted() {
      return self.unhappy != null;
    },
    get darkCirclesStepCompleted() {
      return self.darkCircles != null;
    },
    get lifestyleStepCompleted() {
      return self.lifestyle != null;
    },
    get workStepCompleted() {
      return self.work != null;
    },
    get afraidOfLosingBeautyStepCompleted() {
      return self.afraidOfLosingBeauty != null;
    },
    get appearanceReasonStepCompleted() {
      return self.appearanceReason != null;
    },
    get hearAboutUsStepCompleted() {
      return self.hearAboutUs != null && self.hearAboutUs.length > 0;
    },
    get ebookSwitcherStepCompleted() {
      return self.ebookSwitcher != null;
    },
  }))
  .views((self) => ({
    getMeaningAge: () => {
      switch (self.age) {
        case 0:
          return 18;
        case 1:
          return 25;
        case 2:
          return 35;
        case 3:
          return 45;
        case 4:
          return 55;
        case 5:
          return 65;
        default:
          return 35;
      }
    },
    getSkinColor: () => {
      switch (self.skinColor) {
        case 0:
          return 'Warm ivory';
        case 1:
          return 'Sand';
        case 2:
          return 'Pale ivory';
        case 3:
          return 'Fair';
        case 4:
          return 'Olive';
        case 5:
          return 'Light brown';
        case 6:
          return 'Brown';
        case 7:
          return 'Black brown';
        default:
          return 'Sand';
      }
    },
    getSkinType: () => {
      let dry: { label: TpSkinType; value: number } = {
        label: 'Dry',
        value: 0,
      };
      let combo: { label: TpSkinType; value: number } = {
        label: 'Combination',
        value: 0,
      };
      let oil: { label: TpSkinType; value: number } = {
        label: 'Oily',
        value: 0,
      };
      let normal: { label: TpSkinType; value: number } = {
        label: 'Normal',
        value: 0,
      };
      switch (self.oilyShine) {
        case 0:
          dry.value += 1;
          break;
        case 1:
          normal.value += 1;
          break;
        case 2:
          combo.value += 1;
          break;
        case 3:
          oil.value += 1;
          break;
      }
      switch (self.afterCleanFace) {
        case 0:
          normal.value += 1;
          break;
        case 1:
          combo.value += 1;
          break;
        case 2:
          dry.value += 1;
          break;
        case 3:
          oil.value += 1;
          break;
      }
      switch (self.skinAcne) {
        case 0:
          normal.value += 1;
          dry.value += 1;
          break;
        case 1:
          oil.value += 1;
          break;
        case 2:
          combo.value += 1;
          break;
      }
      switch (self.skinTexture) {
        case 0:
          normal.value += 1;
          oil.value += 1;
          break;
        case 1:
          dry.value += 1;
          combo.value += 1;
          break;
      }
      switch (self.stress) {
        case 0:
          normal.value += 1;
          oil.value += 1;
          break;
        case 1:
          dry.value += 1;
          combo.value += 1;
          break;
      }

      // eslint-disable-next-line unicorn/no-array-reduce
      const skinTypeMaxWithMaxValue = [dry, combo, oil, normal].reduce(
        (
          accum: { label: TpSkinType; value: number },
          current: { label: TpSkinType; value: number },
        ) => {
          if (current.value > accum.value) {
            return current;
          } else if (current.value === accum.value) {
            return combo;
          } else {
            return accum;
          }
        },
        { label: 'Normal', value: -1 },
      );
      return skinTypeMaxWithMaxValue.label;
    },
  }))
  .actions((self) => ({
    setCountry: (value: string) => {
      self.country = value;
    },
    setCountryCode: (value: string) => {
      self.countryCode = value;
    },
    setKnowledge: (value: number) => {
      self.knowledge = value;
    },
    setAge: (value: number) => {
      self.age = value;
    },
    setGoal: (value?: readonly string[]) => {
      self.goal = value;
    },
    setArea: (value?: readonly string[]) => {
      self.area = value;
    },
    setLookYounger: (value: number) => {
      self.lookYounger = value;
    },
    setSkinCareRoutine: (value: number) => {
      self.skinCareRoutine = value;
    },
    setSkinCareProducts: (value?: readonly string[]) => {
      self.skinCareProducts = value;
    },
    setSunSafety: (value: number) => {
      self.sunSafety = value;
    },
    setFacialTreatments: (value: number) => {
      self.facialTreatments = value;
    },
    setBadHabits: (value?: readonly string[]) => {
      self.badHabits = value;
    },
    setEatHabits: (value: number) => {
      self.eatHabits = value;
    },
    setSugarHabits: (value: number) => {
      self.sugarHabits = value;
    },
    setVegetablesHabits: (value: number) => {
      self.vegetablesHabits = value;
    },
    setWaterHabits: (value: number) => {
      self.waterHabits = value;
    },
    setSleepHabits: (value: number) => {
      self.sleepHabits = value;
    },
    setOilyShine: (value: number) => {
      self.oilyShine = value;
    },
    setAfterCleanFace: (value: number) => {
      self.afterCleanFace = value;
    },
    setSkinAcne: (value: number) => {
      self.skinAcne = value;
    },
    setSkinTexture: (value: number) => {
      self.skinTexture = value;
    },
    setSkinColor: (value: number) => {
      self.skinColor = value;
    },
    setSkinProblems: (value?: readonly string[]) => {
      self.skinProblems = value;
    },
    setStress: (value: number) => {
      self.stress = value;
    },
    setFaceShape: (value: number) => {
      self.faceShape = value;
    },
    setJawline: (value: number) => {
      self.jawline = value;
    },
    setUnhappy: (value: number) => {
      self.unhappy = value;
    },
    setDarkCircles: (value: number) => {
      self.darkCircles = value;
    },
    setLifestyle: (value: number) => {
      self.lifestyle = value;
    },
    setWork: (value: number) => {
      self.work = value;
    },
    setAfraidOfLosingBeauty: (value: number) => {
      self.afraidOfLosingBeauty = value;
    },
    setAppearanceReason: (value: number) => {
      self.appearanceReason = value;
    },
    setHearAboutUs: (value?: readonly string[]) => {
      self.hearAboutUs = value;
    },
    setEbookSwitcher: (value: boolean) => {
      self.ebookSwitcher = value;
    },
    fillInAllSteps: () => {
      if (!self.knowledgeStepCompleted) {
        self.knowledge = 0;
      }
      if (!self.ageStepCompleted) {
        self.age = 0;
      }
      if (!self.goalStepCompleted) {
        self.goal = ['get_rid_of_wrinkles'];
      }
      if (!self.areaStepCompleted) {
        self.area = ['forehead'];
      }
      if (!self.lookYoungerStepCompleted) {
        self.lookYounger = 0;
      }
      if (!self.skinCareRoutineStepCompleted) {
        self.skinCareRoutine = 0;
      }
      if (!self.skinCareProductsStepCompleted) {
        self.skinCareProducts = ['cleanser'];
      }
      if (!self.sunSafetyStepCompleted) {
        self.sunSafety = 0;
      }
      if (!self.facialTreatmentsStepCompleted) {
        self.facialTreatments = 0;
      }
      if (!self.badHabitsStepCompleted) {
        self.badHabits = ['smoking'];
      }
      if (!self.eatHabitsStepCompleted) {
        self.eatHabits = 0;
      }
      if (!self.sugarHabitsStepCompleted) {
        self.sugarHabits = 0;
      }
      if (!self.vegetablesHabitsStepCompleted) {
        self.vegetablesHabits = 0;
      }
      if (!self.waterHabitsStepCompleted) {
        self.waterHabits = 0;
      }
      if (!self.sleepHabitsStepCompleted) {
        self.sleepHabits = 0;
      }
      if (!self.oilyShineStepCompleted) {
        self.oilyShine = 0;
      }
      if (!self.afterCleanFaceStepCompleted) {
        self.afterCleanFace = 0;
      }
      if (!self.skinAcneStepCompleted) {
        self.skinAcne = 0;
      }
      if (!self.skinTextureStepCompleted) {
        self.skinTexture = 0;
      }
      if (!self.stressStepCompleted) {
        self.stress = 0;
      }
      if (!self.skinColorStepCompleted) {
        self.skinColor = 0;
      }
      if (!self.skinProblemsStepCompleted) {
        self.skinProblems = ['sensitive_dry'];
      }
      if (!self.faceShapeStepCompleted) {
        self.faceShape = 0;
      }
      if (!self.jawlineStepCompleted) {
        self.jawline = 0;
      }
      if (!self.unhappyStepCompleted) {
        self.unhappy = 0;
      }
      if (!self.darkCirclesStepCompleted) {
        self.darkCircles = 0;
      }
      if (!self.lifestyleStepCompleted) {
        self.lifestyle = 0;
      }
      if (!self.workStepCompleted) {
        self.work = 0;
      }
      if (!self.afraidOfLosingBeautyStepCompleted) {
        self.afraidOfLosingBeauty = 0;
      }
      if (!self.appearanceReasonStepCompleted) {
        self.appearanceReason = 0;
      }
      if (!self.hearAboutUsStepCompleted) {
        self.hearAboutUs = ['tv'];
      }
      if (!self.ebookSwitcherStepCompleted) {
        self.ebookSwitcher = true;
      }
    },
  }))
  .actions((self) => ({
    reset: () => {
      applySnapshot(self, {});
    },
  }));

type QuizStoreType = Instance<typeof QuizStoreModel>;

export interface QuizStore extends QuizStoreType {}
