import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect } from 'react';

let scrollbarWidthMemoized: number | undefined;
const getScrollbarWidth = () => {
  if (scrollbarWidthMemoized != null) return scrollbarWidthMemoized;

  const outer = document.createElement('div');
  outer.style.overflow = 'scroll';
  document.body.append(outer);

  const inner = document.createElement('div');
  outer.append(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.remove();

  scrollbarWidthMemoized = scrollbarWidth;
  return scrollbarWidth;
};

const useBodyScrollLock = ({
  isModalShown,
  getScrollable,
}: {
  isModalShown: boolean;
  getScrollable: () => Element | null | undefined;
}) => {
  useEffect(() => {
    const container = getScrollable();
    if (isModalShown && container) {
      disableBodyScroll(container);
      const { scrollHeight, clientHeight } = document.documentElement;
      if (scrollHeight > clientHeight) {
        document.body.style.setProperty(
          'padding-right',
          `${getScrollbarWidth()}px`,
        );
      }
      return () => {
        enableBodyScroll(container);
      };
    }
    return undefined;
  }, [isModalShown, getScrollable]);
};

export default useBodyScrollLock;
