import '../styles/index.scss'
import Head from "next/head";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import type { AppProps } from 'next/app';
import { ValidationError } from 'yup';
import { ResizeObserver } from '@juggle/resize-observer';
import { RootStoreProvider } from '../components/common/root-store-provider/root-store-provider'
import '../components/common/page-progress/page-progress.scss'
import ErrorBoundary from '../components/common/error-boundary/error-boundary'
import { AnalyticsInject } from '@features/Analytics';
import { IntlProvider } from '@features/intl';

const CookieConsent = dynamic(() => import("src/components/common/CookieConsent"), { ssr: false });
const PageProgress = dynamic(() => import('src/components/common/page-progress/page-progress'))

// Customize yup error formatting
const formatError = ValidationError.formatError
ValidationError.formatError = function (message, parameters) {
  if (!parameters.label) {
    parameters.label = (parameters.path + '')
      .replace(
        /([a-z])([A-Z])/g,
        (_, match1, match2) => `${match1} ${match2.toLowerCase()}`
      )
      .replace(/^[a-z]/, (match) => match.toUpperCase())
  }
  return formatError.call(this, message, parameters)
}

// Polyfill ResizeObserver
if (typeof window !== 'undefined' && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

const pageDescription = 'Facial yoga & massage';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const canonicalUrl = (typeof window !== 'undefined' ? window.location.origin : '' + (router.asPath === "/" ? "" : router.asPath)).split("?")[0];
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <ErrorBoundary>
        <IntlProvider>
          <RootStoreProvider>
            <PageProgress />
            <CookieConsent />
            <Component {...pageProps} />
            <AnalyticsInject />
          </RootStoreProvider>
        </IntlProvider>
      </ErrorBoundary>
    </>
  )
}

export default MyApp
