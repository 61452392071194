import { Instance, applySnapshot, cast, getRoot, types } from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';

import { RootStoreModel } from 'src/models/root-store/root-store';

import * as payments from '../../api/payments';
import {
  SubscriptionPlan,
  SubscriptionPlanModel,
  SubscriptionPlanSnapshot,
} from '../subscription-plan/subscription-plan';

export enum PaymentMethod {
  CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  PAYPAL = 'paypal',
}

export const PaymentsStoreModel = types
  .model('PaymentsStore')
  .props({
    paymentMethod: types.maybe(
      types.enumeration<PaymentMethod>(Object.values(PaymentMethod)),
    ),
    allSubscriptionPlans: types.maybe(types.array(SubscriptionPlanModel)),
    wasPaymentClosed: types.maybe(types.boolean),
    wasPaymentTried: types.maybe(types.boolean),
    isDiscount: types.maybe(types.boolean),
    isPurchaseEventsSend: types.optional(types.boolean, false),
    wasPaymentModalClosed: types.maybe(types.boolean),
    chosenPlanId: types.maybe(types.string),
    joinedPeople: types.optional(types.number, 144),
  })
  .actions((self) => ({
    setSubscriptionPlans: (plans: SubscriptionPlanSnapshot[]) => {
      if (!self.allSubscriptionPlans) {
        self.allSubscriptionPlans = cast(plans);
      } else {
        applySnapshot(self.allSubscriptionPlans, plans);
      }
    },
  }))
  .actions((self) => ({
    fetchSubscriptionPlans: flow(function* ({
      abortSignal,
      group,
      countryCode,
      paddle,
    }: {
      abortSignal?: AbortSignal;
      group?: string;
      countryCode?: string;
      paddle?: boolean;
    } = {}) {
      const plans = yield payments.fetchSubscriptionPlans({
        abortSignal,
        group,
        countryCode,
        paddle,
      });
      self.setSubscriptionPlans(plans);
    }),
  }))
  .actions((self) => ({
    setWasPaymentModalClosed(wasClosed: boolean) {
      self.wasPaymentModalClosed = wasClosed;
    },
    setDiscount(discount: boolean) {
      self.isDiscount = discount;
    },
    setWasPaymentClosed: (wasClosed: boolean) => {
      self.wasPaymentClosed = wasClosed;
    },
    setWasPaymentTried: (wasTried: boolean) => {
      self.wasPaymentTried = wasTried;
    },
    setPaymentMethod: (paymentMethod: PaymentMethod) => {
      self.paymentMethod = paymentMethod;
    },
    setPurchaseEventsSend(isSent: boolean) {
      self.isPurchaseEventsSend = isSent;
    },
    setChosenPlan(chosenPlanId: string | undefined) {
      self.chosenPlanId = chosenPlanId;
    },
    incrementJoinedPeople() {
      self.joinedPeople += 1;
    },
  }))
  .actions((self) => ({
    resetPayment: () => {
      self.wasPaymentClosed = undefined;
      self.wasPaymentTried = undefined;
      self.isDiscount = undefined;
      self.isPurchaseEventsSend = false;
      self.wasPaymentModalClosed = false;
      self.allSubscriptionPlans = undefined;
      self.chosenPlanId = undefined;
      self.joinedPeople = 144;
    },
  }))
  .views((self) => ({
    get subscriptionPlansDiscountPrice() {
      if (!self.allSubscriptionPlans) {
        return [];
      }

      const newPlans: Map<string, SubscriptionPlan> = new Map();

      for (const plan of self.allSubscriptionPlans) {
        newPlans.set(plan.name, plan);

        if (plan.specialOffer) {
          const planFullPrice = self.allSubscriptionPlans.find(
            (item) => item.name === plan.name && !item.specialOffer,
          );

          const existingPlan = newPlans.get(plan.name)!;
          if (!existingPlan.specialOffer) {
            newPlans.delete(existingPlan.name);
          } else {
            newPlans.set(
              plan.name,
              SubscriptionPlanModel.create({
                ...plan,
                order: planFullPrice ? planFullPrice.order : plan.order,
              }),
            );
          }
        }
      }
      return [...newPlans.values()].sort((a, b) => a.order - b.order);
    },
  }))
  .views((self) => ({
    get subscriptionPlans() {
      const { authStore } = getRoot<typeof RootStoreModel>(self);
      if (authStore.variant === 'variant10') {
        return self.allSubscriptionPlans?.filter(
          (plan) => plan.id === self.chosenPlanId,
        );
      }
      if (!self.isDiscount) {
        return self.allSubscriptionPlans?.filter((plan) => !plan.specialOffer);
      }
      return self.allSubscriptionPlans?.filter((plan) => plan.specialOffer);
    },
    getCurrentPlanWithDiscount(currentPlanName: string) {
      return self.allSubscriptionPlans!.find(
        (item) => item.name === currentPlanName && item.specialOffer,
      );
    },
    getDefaultDiscountPercent(currentPlanName: string) {
      return self.allSubscriptionPlans!.find(
        (item) => item.name === currentPlanName && !item.specialOffer,
      )?.discountPercentage;
    },
  }));
type PaymentsStoreType = Instance<typeof PaymentsStoreModel>;

export interface PaymentsStore extends PaymentsStoreType {}
