export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const FACEBOOK_PIXEL_ID_V2 =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_V2_ID;
export const FACEBOOK_PIXEL_ID_V3 =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_V3_ID;
export const GA_ID = process.env.NEXT_PUBLIC_GA_ID;
export const PINTEREST_ID = process.env.NEXT_PUBLIC_PINTEREST_ID;

export const ANALYTICS_ENDPOINT = process.env
  .NEXT_PUBLIC_API_ANALYTICS_ENDPOINT as string;
export const ANALYTICS_ENDPOINT_V2 = process.env
  .NEXT_PUBLIC_API_ANALYTICS_ENDPOINT_V2 as string;
