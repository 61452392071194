import type { BugsnagErrorBoundary as BugsnagErrorBoundaryType } from '@bugsnag/plugin-react';
import useEventListener from '@use-it/event-listener';
import { ComponentProps, PropsWithChildren, useState } from 'react';

import { Image } from 'src/components/common/Image';

import bugsnag from '../../../utils/bugsnag';
import ContactUsLink from '../contact-us-link/contact-us-link';
import AlertWithCloseButton from '../modal/alert-with-close-button/alert-with-close-button';
import Alert from '../modal/alert/alert';

const BugsnagErrorBoundary = bugsnag.getPlugin(
  'react',
) as unknown as BugsnagErrorBoundaryType;

const ErrorAlert = ({
  AlertComponent = AlertWithCloseButton,
  show = true,
  ...props
}: { AlertComponent?: typeof Alert } & ComponentProps<typeof Alert>) => (
  <AlertComponent
    show={show}
    closeButtonText="Refresh page"
    image={
      <Image src="technical-problems.png" width={160} height={120} alt="" />
    }
    title={<>Oops! Something&nbsp;went&nbsp;wrong</>}
    description={
      <>
        We’re trying to fix it up.
        <br />
        Please reload the page or try again later. If the issue persists, feel
        free to <ContactUsLink />.
      </>
    }
    {...props}
  />
);

const ErrorBoundary = ({ children }: PropsWithChildren<{}>) => {
  const [caughtUnhandledRejection, setCaughtUnhandledRejection] =
    useState(false);
  useEventListener(
    'unhandledrejection',
    ({ reason }: PromiseRejectionEvent) => {
      if (
        (reason instanceof Error && reason.name === 'AbortError') ||
        (reason instanceof Error && reason.name === 'NotAllowedError')
      ) {
        return;
      }
      console.log(reason);
      setCaughtUnhandledRejection(true);
    },
  );

  return (
    <BugsnagErrorBoundary FallbackComponent={ErrorAlert}>
      <ErrorAlert
        AlertComponent={AlertWithCloseButton}
        show={caughtUnhandledRejection}
        onHide={() => {
          setCaughtUnhandledRejection(false);
          window.location.reload();
        }}
      />
      {children}
    </BugsnagErrorBoundary>
  );
};

export default ErrorBoundary;
