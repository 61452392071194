import {
  Instance,
  SnapshotOut,
  applySnapshot,
  getRoot,
  types,
} from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';

import {
  WEEK_OFFER_AB_TEST_NAME,
  WeekOfferAbTestGroupName,
} from 'src/constants/analytics';
import { RootStoreModel } from 'src/models/root-store/root-store';

import * as auth from '../../api/auth';
import { User, UserModel, UserSnapshot } from '../user/user';

export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    user: types.maybe(types.union(UserModel, types.null)),
    auth_token: types.maybe(types.string),
    access_code: types.maybe(types.string),
    fbp: types.maybe(types.union(types.string, types.undefined)),
    fbc: types.maybe(types.union(types.string, types.undefined)),
    variant: types.maybe(types.string),
    campaign: types.maybe(types.union(types.string, types.undefined)),
    consentCookie: false,
    googlePseudoID: types.maybe(types.string),
    loginedByWinback: false,
    personalInfoSubmitted: false,
    firstName: types.maybe(types.string),
    lastName: types.maybe(types.string),
    dateOfBirth: types.maybe(types.string),
    gender: types.maybe(types.string),
    paddlePaymentProcessed: types.maybe(types.boolean),
  })

  .actions((self) => ({
    setUser: (user: UserSnapshot | null) => {
      if (!self.user || user === null) {
        self.user = user;
      } else {
        applySnapshot(self.user, user);
      }
    },
    setAuthToken: (token: string) => {
      self.auth_token = token;
    },
    setAccessCode: (code: string) => {
      self.access_code = code;
    },
    setFbp: (fbp: string | undefined) => {
      self.fbp = fbp;
    },
    setFbc: (fbc: string | undefined) => {
      self.fbc = fbc;
    },
    setVariant: (variant: string | undefined) => {
      self.variant = variant;
    },
    setCampaign: (campaign: string | undefined) => {
      self.campaign = campaign;
    },
    setConsentCookie: (value: boolean) => {
      self.consentCookie = value;
    },
    setGooglePseudoID: (code: string) => {
      self.googlePseudoID = code;
    },
    setLoginedByWinback: (value: boolean) => {
      self.loginedByWinback = value;
    },
    setPersonalInfoSubmitted: (value: boolean) => {
      self.personalInfoSubmitted = value;
    },
    setFirstName: (value: string | undefined) => {
      self.firstName = value;
    },
    setLastName: (value: string | undefined) => {
      self.lastName = value;
    },
    setDateOfBirth: (value: string | undefined) => {
      self.dateOfBirth = value;
    },
    setGender: (value: string | undefined) => {
      self.gender = value;
    },
    setPaddlePaymentProcessed: (value: boolean | undefined) => {
      self.paddlePaymentProcessed = value;
    },
  }))
  .actions((self) => ({
    fetchUser: flow(function* () {
      const user = yield auth.fetchUser();
      self.setUser(user);
      self.setAuthToken(user?.token);
    }),
    register: flow(function* ({
      email,
      age,
      abortSignal,
    }: {
      email: string;
      age: number;
      abortSignal?: AbortSignal;
    }) {
      const user = yield auth.register({ email, age, abortSignal });
      self.setAccessCode(user.accessCode);
    }),
    setPasswordAndEmail: flow(function* ({
      email,
      password,
      abortSignal,
    }: {
      email: string;
      password: string;
      abortSignal?: AbortSignal;
    }) {
      yield auth.setPasswordAndEmail({ email, password, abortSignal });
      const user = self.user as User;
      user.setEmail(email);
      user.setPasswordSet();
    }),
    logOut: flow(function* () {
      yield auth.logOut();
      self.setUser(null);
      self.setAuthToken('');
      self.setAccessCode('');
      self.setGooglePseudoID('');
      self.setLoginedByWinback(false);
      self.setPersonalInfoSubmitted(false);
      self.setFirstName(undefined);
      self.setLastName(undefined);
      self.setDateOfBirth(undefined);
      self.setGender(undefined);
      self.setPaddlePaymentProcessed(undefined);
    }),
    loginBySafariAuthToken: flow(function* ({
      safariAuthToken,
    }: {
      safariAuthToken: string;
    }) {
      const user = yield auth.loginBySafariAuthToken({ safariAuthToken });
      self.setUser(user);
      self.setAccessCode(user.accessCode);
    }),
    loginByEmailToken: flow(function* ({
      emailToken,
      abortSignal,
    }: {
      emailToken: string;
      abortSignal?: AbortSignal;
    }) {
      const user = yield auth.loginWithEmailToken({ emailToken, abortSignal });
      self.setUser(user);
      user.access_code && self.setAccessCode(user.access_code);
    }),
  }))
  .views((self) => ({
    get isPaddleVariants() {
      return self.variant === 'variant9' || self.variant === 'variant10';
    },
    get showPriceValue() {
      const { analyticsStore } = getRoot<typeof RootStoreModel>(self);
      return (
        self.variant !== 'variant8' &&
        self.variant !== 'variant9' &&
        self.variant !== 'variant10' &&
        self.variant !== 'variant13' &&
        self.variant !== 'tw1' &&
        analyticsStore.abTests.get(WEEK_OFFER_AB_TEST_NAME)?.group_name !==
          WeekOfferAbTestGroupName.NEW
      );
    },
  }));

type AuthStoreType = Instance<typeof AuthStoreModel>;

export interface AuthStore extends AuthStoreType {}

type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;

export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
