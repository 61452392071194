import type { ModalHandle } from '@restart/ui/Modal';
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

import useBodyScrollLock from '../../../../utils/hooks/use-body-scroll-lock';
import styles from './modal-container.module.scss';

const ModalContainer = ({
  className,
  backdropClassName,
  dialogClassName,
  ...props
}: ModalProps) => {
  const { show } = props;

  const modalRef = useRef<ModalHandle>(null);
  useBodyScrollLock({
    isModalShown: show ?? false,
    getScrollable: useCallback(() => modalRef.current?.dialog, []),
  });

  return (
    <Modal
      ref={modalRef}
      backdropClassName={classNames(styles.backdrop, backdropClassName)}
      className={classNames(styles.container, className)}
      dialogClassName={classNames(styles.modal, dialogClassName)}
      restoreFocusOptions={{ preventScroll: true }}
      {...props}
    />
  );
};

export default ModalContainer;
