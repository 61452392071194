import { ReactElement, ReactNode } from 'react';

import type { TpMessageKeys } from 'src/features/intl';

export enum QuizStepType {
  OTHER,
  TEXT_CARD,
  SINGLE_OPTION_SELECT,
  SINGLE_OPTION_SELECT_WITH_BACKGROUND_IMAGE,
  SINGLE_OPTION_WITH_IMAGE_SELECT,
  MULTIPLE_OPTIONS_SELECT,
  MULTIPLE_OPTIONS_WITH_IMAGE_SELECT,
  REVIEWS,
  ACHIEVE_RESULTS,
}

export type QuizSection =
  | 'Introduction'
  | 'Goals'
  | 'Face care'
  | 'Habits'
  | 'Your skin'
  | 'Feelings'
  | 'Final Screens';

export enum QuizStep {
  WELCOME_KNOWLEDGE,
  ABOUT_FY,
  REVIEWS,
  AGE,
  GOAL,
  AREA,
  LOOK_YOUNGER,
  GOALS_SUMMARY,
  SKIN_CARE_ROUTINE,
  SKIN_CARE_PRODUCT,
  SUN_SAFETY,
  FACIAL_TREATMENTS,
  FACE_CARE_SUMMARY,
  BAD_HABITS,
  EAT_HABITS,
  SUGAR_HABITS,
  VEGETABLES_HABITS,
  WATER_HABITS,
  SLEEP_HABITS,
  ABOUT_BOOK,
  HABITS_SUMMARY,
  SKIN_TYPE,
  OILY_SHINE,
  AFTER_CLEAN_FACE,
  SKIN_ACNE,
  SKIN_TEXTURE,
  SKIN_COLOR,
  SKIN_PROBLEMS_WHITE,
  SKIN_PROBLEMS_BLACK,
  STRESS,
  SKIN_RECOMMEND,
  FIRST_CHANGES,
  FACE_SHAPE,
  JAWLINE,
  UNHAPPY,
  DARK_CIRCLES,
  SKIN_SUMMARY,
  LIFESTYLE,
  WORK,
  AFRAID_LOSE_BEAUTY,
  APPEARANCE_REASON,
  SPECIAL_FOR_YOU,
  VARIOUS_TOPICS,
  TRACK_PROGRESS,
  HEAR_ABOUT_US,
  ACHIEVE_RESULTS,
  EXPECT,
  FINAL_SUMMARY,
  ENTER_EMAIL,
  ENTER_EMAIL_ABTEST_ID4,
  RECEIVE_EMAILS,
}

interface BaseQuizStep {
  type: QuizStepType;
  section: QuizSection;
  step: QuizStep;
  nextButtonText?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  TitleComponent?: ReactNode;
  SubTitleComponent?: ReactNode;
}

export interface OtherQuizStep extends BaseQuizStep {
  type: QuizStepType.OTHER;
  options?: SingleOptionSelectQuizStepOption[];
  background?: ColoredBackgroundQuizStepBackground;
}

export interface TextCardQuizStep extends BaseQuizStep {
  type: QuizStepType.TEXT_CARD;
  image?: ReactNode;
  background: ColoredBackgroundQuizStepBackground;
}

export interface SelectQuizStepOption<T> {
  value: T;
  label: ReactNode;
}

export interface SingleOptionSelectQuizStepOption
  extends SelectQuizStepOption<number> {
  image?: ReactNode;
  imageColor?: string;
}

export type TpReviewsQuizStepReview = {
  image: ReactNode;
  title: {
    id: TpMessageKeys;
    defaultMessage: string;
  };
  description: {
    id: TpMessageKeys;
    defaultMessage: string;
  };
};
export type TpReviewsQuizStep = BaseQuizStep & {
  type: QuizStepType.REVIEWS;
};

export type TpAchieveResultsQuizStep = BaseQuizStep & {
  type: QuizStepType.ACHIEVE_RESULTS;
};

export interface SingleOptionSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.SINGLE_OPTION_SELECT;
  options: SingleOptionSelectQuizStepOption[];
  background?: ColoredBackgroundQuizStepBackground;
  image?: ReactNode;
}
export type TpSingleOptionSelectBackgroundImageQuizStep = BaseQuizStep & {
  type: QuizStepType.SINGLE_OPTION_SELECT_WITH_BACKGROUND_IMAGE;
  options: SingleOptionSelectQuizStepOption[];
  backgroundImages: ReactElement[];
};

export interface SingleOptionImageSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.SINGLE_OPTION_WITH_IMAGE_SELECT;
  options: SingleOptionSelectQuizStepOption[];
}

export interface MultipleOptionsSelectQuizStepOption
  extends SelectQuizStepOption<string> {
  image?: ReactNode;
}

export interface MultipleOptionsSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.MULTIPLE_OPTIONS_SELECT;
  options: MultipleOptionsSelectQuizStepOption[];
  skipButtonText?: string;
  extraContent?: ReactNode;
}

export interface MultipleOptionsImageSelectQuizStep extends BaseQuizStep {
  type: QuizStepType.MULTIPLE_OPTIONS_WITH_IMAGE_SELECT;
  options: MultipleOptionsSelectQuizStepOption[];
  skipButtonText?: string;
}

export enum ColoredBackgroundQuizStepBackground {
  WHITE,
  BROWN,
  BROWN_LIGHT,
  CREAM,
  BLUE,
  PASTEL_GREEN,
  GREEN,
  PINK,
  DEFAULT,
  GREY,
  LIGHT_GREY,
  SOFT_GREEN,
  LIGHT_BEIGE,
  LIGHT_BLUE,
}

export enum ConditionalClassNamesSingleOptionWithImage {
  LOOK_YOUNGER,
  SLEEP_HABITS,
}

export type TpStep =
  | OtherQuizStep
  | TextCardQuizStep
  | SingleOptionSelectQuizStep
  | TpSingleOptionSelectBackgroundImageQuizStep
  | SingleOptionImageSelectQuizStep
  | MultipleOptionsSelectQuizStep
  | MultipleOptionsImageSelectQuizStep
  | TpReviewsQuizStep
  | TpAchieveResultsQuizStep;

export const isStepHeaderShown = (step: TpStep) => {
  switch (step.type) {
    case QuizStepType.OTHER:
      switch (step.step) {
        case QuizStep.FINAL_SUMMARY:
        case QuizStep.ENTER_EMAIL:
        case QuizStep.ENTER_EMAIL_ABTEST_ID4:
        case QuizStep.RECEIVE_EMAILS:
          return false;

        default:
          return true;
      }

    default:
      return true;
  }
};

export const isStepProgressShown = (step: TpStep) => {
  switch (step.type) {
    case QuizStepType.OTHER:
      switch (step.step) {
        case QuizStep.EXPECT:
        case QuizStep.WELCOME_KNOWLEDGE:
        case QuizStep.FACE_CARE_SUMMARY:
        case QuizStep.SKIN_RECOMMEND:
        case QuizStep.FIRST_CHANGES:
        case QuizStep.ABOUT_BOOK:
          return false;
        default:
          return true;
      }
    case QuizStepType.TEXT_CARD:
    case QuizStepType.ACHIEVE_RESULTS:
      return false;

    default:
      return true;
  }
};

export enum QuizAgeOption {
  FROM_18_TO_24 = 0,
  FROM_25_TO_34 = 1,
  FROM_35_TO_44 = 2,
  FROM_45_TO_54 = 3,
  FROM_55_TO_64 = 4,
  FROM_65 = 5,
}

export enum OptionVariant {
  DEFAULT,
  GREEN,
}
