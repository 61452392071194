import Clarity from '@features/Analytics/components/Clarity';
import PinterestPixel from '@features/Analytics/components/PinterestPixel';
import TwitterPixel from '@features/Analytics/components/TwitterPixel';

import { FACEBOOK_PIXEL_ID, GA_ID, PINTEREST_ID } from '../../constants';
import FbPixel from '../FacebookPixel';
import GoogleAnalytics from '../GoogleAnalytics';

export const AnalyticsInject = () => (
  <>
    {FACEBOOK_PIXEL_ID && <FbPixel />}
    {GA_ID && <GoogleAnalytics />}
    {PINTEREST_ID && <PinterestPixel />}
    {process.env.NEXT_PUBLIC_ENV === 'production' && <TwitterPixel />}
    {process.env.NEXT_PUBLIC_ENV === 'production' && <Clarity />}
  </>
);
