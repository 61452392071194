import { FormattedMessage as Message } from 'react-intl';

import { TpFormattedMessage } from '../../types';

export const FormattedMessage = (props: TpFormattedMessage) => {
  const { defaultMessage, id, ...otherProps } = props;

  return (
    <Message
      data-trans={id}
      id={id}
      defaultMessage={defaultMessage}
      {...otherProps}
    />
  );
};
