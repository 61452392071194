export enum PersonalInfoScreenAbTestGroupName {
  WITHOUT_SCREEN = 'A. Without screen',
  WITH_SCREEN = 'B. With new screen',
}
export const PERSONAL_INFO_SCREEN_AB_TEST_NAME =
  'Mimika | FE-618 personal info screen 4 FB';

export const PERSONAL_INFO_USER_PROPERTY_NAME = 'ab_test_fe618';

export enum CookiesAbTestGroupName {
  SHOW = 'A. With cookies',
  HIDE = 'B. Without cookies',
}
export const COOKIES_AB_TEST_NAME = 'FE-603 | Mimika – Cookies';

export const COOKIES_USER_PROPERTY_NAME = 'ab_test_fe603';

export enum EnterEmailAbTestGroupName {
  HIDE = 'A. Current_screen_email',
  SHOW = 'B. New_screen',
}

export const ENTER_EMAIL_AB_TEST_NAME = 'Mimika - ID-4 FE 691 screen e-mail';
export const ENTER_EMAIL_AB_TEST_PROPERTY_NAME = 'ab_test_fe691';

// AB Test week offer instead of variant 12

export enum WeekOfferAbTestGroupName {
  CURRENT = 'A current month offer',
  NEW = 'B new week offer',
}

export const WEEK_OFFER_AB_TEST_NAME = 'Mimika: week offer FE-699';
export const WEEK_OFFER_AB_TEST_PROPERTY_NAME = 'ab_test_fe699';

// AB Test ID 1 new screens + present (eBook) on subscription plan

export enum EBookAbTestGroupName {
  CURRENT = 'A current',
  NEW = 'B new',
}

export const EBOOK_AB_TEST_NAME = 'Mimika – ID-1, re-split by the e-book';
export const EBOOK_AB_TEST_PROPERTY_NAME = 'ab_test_fe687';

// AB Test ID 12 additional offers subscription plan

export enum AdditionalOffersAbTestGroupName {
  CURRENT = 'A current',
  NEW = 'B new',
}

export const ADDITIONAL_OFFERS_AB_TEST_NAME = 'MImika - repeat offer paywall';
export const ADDITIONAL_OFFERS_AB_TEST_PROPERTY_NAME = 'ab_test_fe697';

// AB Test ID _ organic model

// export enum OrganicModelAbTestGroupName {
//   CURRENT = 'A month',
//   NEW = 'B week',
// }
//
// export const ORGANIC_MODEL_AB_TEST_NAME = 'Mimika – organic model';
// export const ORGANIC_MODES_AB_TEST_PROPERTY_NAME = 'ab_test_fe708';
