import { Instance, applySnapshot, types } from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';

import * as analytics from '@features/Analytics/api/analytics';

import { AbTestModel, AbTestSnapshot } from '../ab-test/ab-test';

export const AnalyticsStoreModel = types
  .model('AnalyticsStore')
  .props({
    abTests: types.map(types.optional(AbTestModel, {})),
  })
  .actions((self) => ({
    saveAbTest: (name: string, responseObject: any) => {
      self.abTests.set(name, responseObject as AbTestSnapshot | undefined);
    },
  }))
  .actions((self) => ({
    getAbTest: flow(function* ({
      name,
      userId,
      abortSignal,
    }: {
      name: string;
      userId?: number | null;
      abortSignal?: AbortSignal;
    }) {
      try {
        const responseObject = yield analytics.getAbTest({
          name,
          userId,
          abortSignal,
        });
        self.saveAbTest(name, responseObject);
      } catch (error) {
        self.saveAbTest(name, undefined);
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    reset: () => {
      applySnapshot(self, {});
    },
  }));

type AnalyticsStoreType = Instance<typeof AnalyticsStoreModel>;

export interface AnalyticsStore extends AnalyticsStoreType {}
