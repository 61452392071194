import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

const client = bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
  appVersion: process.env.PACKAGE_VERSION,
  releaseStage:
    process.env.NODE_ENV === 'production'
      ? process.env.NEXT_PUBLIC_ENV === 'development'
        ? 'staging'
        : 'production'
      : 'development',
  enabledReleaseStages: ['staging', 'production'],
  plugins: [new BugsnagPluginReact(React)],
  onError: ({ originalError: error }) =>
    !(error instanceof Error) || error.name !== 'AbortError',
});

export default client;
